
.group-name {
 padding: 35px 0 35px 0;
}

.player {
  display: flex;
  justify-content: space-between;
  border: 1px solid black;
  margin-bottom: 5px;
  padding: 5px 5px;
  width: 45%;
  border-radius: 7px;
  background-color: rgba(2,17,27,.97);
}

.red {
  background-color: red;
  color: black;
}

.all-players-header, .all-players-subheader {
  color: #02111B;
  padding-top: 15px;
}

.all-players-subheader {
  font-size: 12px;
}

.icon-button {
  margin: 0 0;
  padding: 0 0;
  background-color: rgba(255,255,255, 0);
  border: none; 
  color: #fffeff;
}

.icon-button:hover {
  cursor: pointer;
  color: #badbee;
}

.all-players {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 0;
  padding: 0 0 10px 0;
}

.all-players-section {
  color: #fffeff;
  box-shadow: 0 5px 20px rgba(0,0,0, .8); 
  width: 90%;
  margin-left: 5%;
  border-radius: 20px;
  background-color:rgba(255,255,255);
}

.team-button-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 30px;
}

.teams-page-buttons {
  padding: 15px 20px;
  border: .75px solid black;
  font-size: 16px;
  font-weight: 700;
  background-color: #fffeff;
  margin: 10px 0;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0,0,0, .8); 
}

.teams-page-buttons:hover {
  box-shadow: 0 5px 10px rgba(255,255,255, .8);
  cursor: pointer;
}

.delete{
  text-align: right;
}

@media screen and (min-width: 480px) {
  .team-button-div {
    width: 50%;
    margin-left: 25%;
  }

  .team-name, .group-name{
    font-size: 35px;
  }
  .all-players-subheader {
    font-size: 16px;
  }
}

