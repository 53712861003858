@import url(https://fonts.googleapis.com/css?family=Lato:400,700,700i|Titillium+Web:600,700,700i&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  box-sizing: border-box;
  background-image: url(/static/media/volleyMobiletiny.ba1ab61a.jpg);
  background-size: cover;
  min-height: 100vh;
  font-family: 'Titillium Web', sans-serif;
  padding-bottom: 35px;
}

footer p {
  margin: 0 0;
  position: fixed;
  bottom:0;
  left:0;
  right: 0;
  font-size: 12px;
  background-color: black;
  color: #fffeff;
  padding: 2px 0;
}

.main-section {
  padding-top: 60px;
}


@media screen and (min-width: 480px) {
  .App {
    background-image: url(/static/media/volleyball2tiny.9bae55d2.jpg);
    background-size: cover;
    
  }

  .main-section {
    padding-top: 100px;
  }

}






.main_page {
  color: #02111B;
  width: 80%;
  margin-left: 10%;
  margin-top: 40px;
  background-color: rgba(255,254,255);
  border: 1px solid black;
}

.main_header {
  margin-top: 0px;
  padding-top: 20px;
  font-weight: 700;
  
}

.sec_header{
  padding: 0 2%;
  font-size: 16px;
}

.sub-text {
  padding: 0 2%;
}

.main_page a {
  text-decoration: none;
  color:#02111B;
  font-size: 16px;
  font-weight: 700;
}


.main_page a:hover {
  box-shadow: 0 5px 5px rgba(0,0,0, .3); 
}

.main_page .Main__not-logged-in {
  display: flex; 
  justify-content: space-around;
  margin-top: 40px;
}

@media screen and (min-width: 480px) {
  .main_page {
    width: 54%;
    margin-left: 23%;
    margin-top: 40px;
  }

  .main_page h4 {
    font-size: 18px;
  }

  .sec_header{
    font-size: 20px;
  }

  .main_header {
    font-size: 40px;
  }
}
.login-page {
  margin-top: 20px;
  justify-content: left; 
  width: 80%;
  margin-left: 10%;
  border-radius: 20px;
  background-color:rgba(255,255,255);
  color: #02111B;
  box-shadow: 0 5px 20px rgba(0,0,0, .8); 
}


.login-page form {
  text-align: left;
  margin: 5px 5px 30px 5px;
  /* padding-left: 25%; */
}

.login-page h2 {
  padding-top: 20px;
}

.form-input-section {
  display: block;
  text-align: left; 
}

.form-input-section input {
  display: block;
  height: 35px;
  width: 99%;
  font-size: 15px;
  border: 1px solid black;
  border-radius: 5px;
  margin: 10px 0;
}

.form-input-section label {
  font-size: 15px;
}


@media screen and (min-width: 480px) {
  .login-page {
    margin-top: 20px;
    justify-content: left; 
    width: 40%;
    margin-left: 30%;
    border: 1px solid black;
  }
 
}



.error-message {
  font-weight: bold;
  color: red;
}
.spinner-div {
  text-align: center;

}
.loader {
  /* border: 16px solid #f3f3f3; 
  border-top: 16px solid #3498db; */
  /* font-size: 20px; */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
  color: rgb(0,159,183)
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.registration-page {
  margin-top: 20px;
  justify-content: left; 
  width: 80%;
  margin-left: 10%;
  border-radius: 20px;
  background-color:rgb(255,255,255);
  color: #02111B;
  box-shadow: 0 5px 20px rgba(0,0,0, .8); 
}

.registration-page h2 {
  padding-top: 20px;
}


.registration-page form {
  text-align: left;
  margin: 5px 5px 30px 5px;
  /* padding-left: 25%; */
}

.form-input-section {
  display: block;
  text-align: left; 
 
}

.form-input-section input {
  display: block;
  height: 35px;
  width: 99%;
  font-size: 15px;
}

.form-input-section label {
  font-size: 15px;
}




@media screen and (min-width: 480px) {
  .registration-page {
    margin-top: 20px;
    justify-content: left; 
    width: 40%;
    margin-left: 30%;
    border: 1px solid black;
  }
 
}

.groups-page {
  margin-top: 20px;
  justify-content: left; 
  width: 80%;
  margin-left: 10%;
  border-radius: 20px;
  background-color:rgba(255,255,255);
  color: #02111B;
  box-shadow: 0 5px 20px rgba(0,0,0, .8); 
}

.groups-list {
  list-style: none;
  padding: 0
}

.group-header {
  padding-top: 20px;
}
/* Might not like the group colors */
.groups-list li{
  padding: 7px 0;
  width: 95%;
  margin-left: 2%;
  margin-bottom: 5px;
  border: 1px solid #282C34;
  background-color: rgba(218,255,239,.9);
  border-radius: 10px;
}

.groups-list li:hover {
  box-shadow: 0 0 10px rgba(255,255,255, .8);
}

.group-input {
  margin: 10px 0;
}

.groups-list a{
 color: black;
  text-decoration: none;
}

.create-group-button {
  padding: 15px 20px;
  border: .75px solid black;
  font-size: 16px;
  font-weight: 700;
  background-color: #fffeff;
  margin: 10px 0;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0,0,0, .8); 
}

.create-group-button:hover {
  box-shadow: 0 5px 5px rgba(0,0,0, .3); 
  cursor: pointer;
}

.group-form {
  text-align: left;
  margin: 5px 5px 30px 5px;
}



@media screen and (min-width: 480px) {
  .groups-page {
    width: 40%;
    margin-left: 30%;
    justify-content: left;
  }

  .groups-list li{
    padding: 10px 0;
  }
}

.Header {
  height: 40px;
  position: fixed;
  top: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #465362;
  font-family: 'Titillium Web', sans-serif;
}

.Header h1 {
  margin: 0 5px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 700;
}

.Header a {
  color:#fffeff;
  text-decoration: none;
}

.Header__not-logged-in {
  
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: flex-end;
}

.Header__not-logged-in a:last-child {
  background: #fffeff;
  color: #02111B;
  padding: 5px 15px;
  border-radius: 15px;
  margin-left: 10px;
}

.Header__logged-in a {
  border: 1px solid #f8fbfb;
  padding: 5px 15px;
  border-radius: 20px;
}

@media screen and (min-width: 450px) {
 

  .Header h1 {
    font-size: 22pt;
  }
}


.button {
  padding: 10px 20px;
  font-weight: 700;
  font-size: 16px;
  margin:10px 10px 20px 0;
  border: .75px solid black;
  background-color: #fffeff;
  border-radius: 10px;
  box-shadow: 0 5px 5px rgba(0,0,0, .6); 
}

.button:hover {
  box-shadow: 0 5px 5px rgba(0,0,0, .3); 
  cursor: pointer;
}

.first-button {
  margin-left: 10%;
}

.player-form { 
  width: 80%;
  margin: 40px 5%;
  text-align: left;
  box-shadow: 0 5px 20px rgba(0,0,0, .8); 
  width: 90%;

  border-radius: 20px;
  background-color:rgba(255,255,255);
}

.player-form h3 {
  text-align: center;
  padding-top: 15px;
}

.player-form select {
  display: block;
  height: 35px;
  width: 40%;
  font-size: 15px;
  border: 1px solid black;
  background-color: #fffeff;
  margin: 10px 0;
}

.player-form input {
  margin: 10px 0;
  border: 1px solid black;
  border-radius: 5px;
}

.player-form .form-input-section {
  margin-left: 10%;
  width: 80%;
}

#playerName, #playerSkill{
  background-color: #FDFDFF;
  padding: 5px 10px 5px 1px;
  border-radius: 10%;
  margin: 5px 5px;
}

@media screen and (min-width: 480px) {
  .player-form { 
    width: 64%;
    margin-left: 18%;
  }

  .player-form select {
    display: block;
    height: 35px;
    width: 27%;
    font-size: 15px;
  }
}








.row {
  width: 96%;
  margin-left: 2%;
  display: flex;
  justify-content: space-around;
  flex-flow: wrap;
  padding-bottom: 40px;
}

.team-player {
  border: 1px solid black;
  margin-bottom: 5px;
  padding: 5px 5px;
  border-radius: 7px;
  background-color: rgba(218,255,239,.4)
}

.teams:nth-child(2n) .team-player{
background-color: rgba(0,159,183,.2)
}

.team-list {
  list-style: none;
  padding: 0 15px;
}

.teams {
  margin-top: 40px;
  width: 90%;
  font-size: 16px;
  border-radius: 10px;
  background-color:rgba(255,255,255);
  color: #02111B;
  box-shadow: 0 5px 20px rgba(0,0,0, .8); 
}

.team-name, .group-name{
  font-size: 25px;
}

@media screen and (min-width: 480px) {
  .teams {
    margin-top: 40px;
    width: 45%;
    font-size: 16px;
  }
}


.group-name {
 padding: 35px 0 35px 0;
}

.player {
  display: flex;
  justify-content: space-between;
  border: 1px solid black;
  margin-bottom: 5px;
  padding: 5px 5px;
  width: 45%;
  border-radius: 7px;
  background-color: rgba(2,17,27,.97);
}

.red {
  background-color: red;
  color: black;
}

.all-players-header, .all-players-subheader {
  color: #02111B;
  padding-top: 15px;
}

.all-players-subheader {
  font-size: 12px;
}

.icon-button {
  margin: 0 0;
  padding: 0 0;
  background-color: rgba(255,255,255, 0);
  border: none; 
  color: #fffeff;
}

.icon-button:hover {
  cursor: pointer;
  color: #badbee;
}

.all-players {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 0;
  padding: 0 0 10px 0;
}

.all-players-section {
  color: #fffeff;
  box-shadow: 0 5px 20px rgba(0,0,0, .8); 
  width: 90%;
  margin-left: 5%;
  border-radius: 20px;
  background-color:rgba(255,255,255);
}

.team-button-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 30px;
}

.teams-page-buttons {
  padding: 15px 20px;
  border: .75px solid black;
  font-size: 16px;
  font-weight: 700;
  background-color: #fffeff;
  margin: 10px 0;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0,0,0, .8); 
}

.teams-page-buttons:hover {
  box-shadow: 0 5px 10px rgba(255,255,255, .8);
  cursor: pointer;
}

.delete{
  text-align: right;
}

@media screen and (min-width: 480px) {
  .team-button-div {
    width: 50%;
    margin-left: 25%;
  }

  .team-name, .group-name{
    font-size: 35px;
  }
  .all-players-subheader {
    font-size: 16px;
  }
}


