.main_page {
  color: #02111B;
  width: 80%;
  margin-left: 10%;
  margin-top: 40px;
  background-color: rgba(255,254,255);
  border: 1px solid black;
}

.main_header {
  margin-top: 0px;
  padding-top: 20px;
  font-weight: 700;
  
}

.sec_header{
  padding: 0 2%;
  font-size: 16px;
}

.sub-text {
  padding: 0 2%;
}

.main_page a {
  text-decoration: none;
  color:#02111B;
  font-size: 16px;
  font-weight: 700;
}


.main_page a:hover {
  box-shadow: 0 5px 5px rgba(0,0,0, .3); 
}

.main_page .Main__not-logged-in {
  display: flex; 
  justify-content: space-around;
  margin-top: 40px;
}

@media screen and (min-width: 480px) {
  .main_page {
    width: 54%;
    margin-left: 23%;
    margin-top: 40px;
  }

  .main_page h4 {
    font-size: 18px;
  }

  .sec_header{
    font-size: 20px;
  }

  .main_header {
    font-size: 40px;
  }
}