.spinner-div {
  text-align: center;

}
.loader {
  /* border: 16px solid #f3f3f3; 
  border-top: 16px solid #3498db; */
  /* font-size: 20px; */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  color: rgb(0,159,183)
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}