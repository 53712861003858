.button {
  padding: 10px 20px;
  font-weight: 700;
  font-size: 16px;
  margin:10px 10px 20px 0;
  border: .75px solid black;
  background-color: #fffeff;
  border-radius: 10px;
  box-shadow: 0 5px 5px rgba(0,0,0, .6); 
}

.button:hover {
  box-shadow: 0 5px 5px rgba(0,0,0, .3); 
  cursor: pointer;
}

.first-button {
  margin-left: 10%;
}

.player-form { 
  width: 80%;
  margin: 40px 5%;
  text-align: left;
  box-shadow: 0 5px 20px rgba(0,0,0, .8); 
  width: 90%;

  border-radius: 20px;
  background-color:rgba(255,255,255);
}

.player-form h3 {
  text-align: center;
  padding-top: 15px;
}

.player-form select {
  display: block;
  height: 35px;
  width: 40%;
  font-size: 15px;
  border: 1px solid black;
  background-color: #fffeff;
  margin: 10px 0;
}

.player-form input {
  margin: 10px 0;
  border: 1px solid black;
  border-radius: 5px;
}

.player-form .form-input-section {
  margin-left: 10%;
  width: 80%;
}

#playerName, #playerSkill{
  background-color: #FDFDFF;
  padding: 5px 10px 5px 1px;
  border-radius: 10%;
  margin: 5px 5px;
}

@media screen and (min-width: 480px) {
  .player-form { 
    width: 64%;
    margin-left: 18%;
  }

  .player-form select {
    display: block;
    height: 35px;
    width: 27%;
    font-size: 15px;
  }
}







