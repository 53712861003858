.groups-page {
  margin-top: 20px;
  justify-content: left; 
  width: 80%;
  margin-left: 10%;
  border-radius: 20px;
  background-color:rgba(255,255,255);
  color: #02111B;
  box-shadow: 0 5px 20px rgba(0,0,0, .8); 
}

.groups-list {
  list-style: none;
  padding: 0
}

.group-header {
  padding-top: 20px;
}
/* Might not like the group colors */
.groups-list li{
  padding: 7px 0;
  width: 95%;
  margin-left: 2%;
  margin-bottom: 5px;
  border: 1px solid #282C34;
  background-color: rgba(218,255,239,.9);
  border-radius: 10px;
}

.groups-list li:hover {
  box-shadow: 0 0 10px rgba(255,255,255, .8);
}

.group-input {
  margin: 10px 0;
}

.groups-list a{
 color: black;
  text-decoration: none;
}

.create-group-button {
  padding: 15px 20px;
  border: .75px solid black;
  font-size: 16px;
  font-weight: 700;
  background-color: #fffeff;
  margin: 10px 0;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0,0,0, .8); 
}

.create-group-button:hover {
  box-shadow: 0 5px 5px rgba(0,0,0, .3); 
  cursor: pointer;
}

.group-form {
  text-align: left;
  margin: 5px 5px 30px 5px;
}



@media screen and (min-width: 480px) {
  .groups-page {
    width: 40%;
    margin-left: 30%;
    justify-content: left;
  }

  .groups-list li{
    padding: 10px 0;
  }
}
