.row {
  width: 96%;
  margin-left: 2%;
  display: flex;
  justify-content: space-around;
  flex-flow: wrap;
  padding-bottom: 40px;
}

.team-player {
  border: 1px solid black;
  margin-bottom: 5px;
  padding: 5px 5px;
  border-radius: 7px;
  background-color: rgba(218,255,239,.4)
}

.teams:nth-child(2n) .team-player{
background-color: rgba(0,159,183,.2)
}

.team-list {
  list-style: none;
  padding: 0 15px;
}

.teams {
  margin-top: 40px;
  width: 90%;
  font-size: 16px;
  border-radius: 10px;
  background-color:rgba(255,255,255);
  color: #02111B;
  box-shadow: 0 5px 20px rgba(0,0,0, .8); 
}

.team-name, .group-name{
  font-size: 25px;
}

@media screen and (min-width: 480px) {
  .teams {
    margin-top: 40px;
    width: 45%;
    font-size: 16px;
  }
}
