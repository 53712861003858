.login-page {
  margin-top: 20px;
  justify-content: left; 
  width: 80%;
  margin-left: 10%;
  border-radius: 20px;
  background-color:rgba(255,255,255);
  color: #02111B;
  box-shadow: 0 5px 20px rgba(0,0,0, .8); 
}


.login-page form {
  text-align: left;
  margin: 5px 5px 30px 5px;
  /* padding-left: 25%; */
}

.login-page h2 {
  padding-top: 20px;
}

.form-input-section {
  display: block;
  text-align: left; 
}

.form-input-section input {
  display: block;
  height: 35px;
  width: 99%;
  font-size: 15px;
  border: 1px solid black;
  border-radius: 5px;
  margin: 10px 0;
}

.form-input-section label {
  font-size: 15px;
}


@media screen and (min-width: 480px) {
  .login-page {
    margin-top: 20px;
    justify-content: left; 
    width: 40%;
    margin-left: 30%;
    border: 1px solid black;
  }
 
}


