.Header {
  height: 40px;
  position: fixed;
  top: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #465362;
  font-family: 'Titillium Web', sans-serif;
}

.Header h1 {
  margin: 0 5px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 700;
}

.Header a {
  color:#fffeff;
  text-decoration: none;
}

.Header__not-logged-in {
  
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

.Header__not-logged-in a:last-child {
  background: #fffeff;
  color: #02111B;
  padding: 5px 15px;
  border-radius: 15px;
  margin-left: 10px;
}

.Header__logged-in a {
  border: 1px solid #f8fbfb;
  padding: 5px 15px;
  border-radius: 20px;
}

@media screen and (min-width: 450px) {
 

  .Header h1 {
    font-size: 22pt;
  }
}

