@import url('https://fonts.googleapis.com/css?family=Lato:400,700,700i|Titillium+Web:600,700,700i&display=swap');
.App {
  text-align: center;
  box-sizing: border-box;
  background-image: url('./volleyMobiletiny.jpg');
  background-size: cover;
  min-height: 100vh;
  font-family: 'Titillium Web', sans-serif;
  padding-bottom: 35px;
}

footer p {
  margin: 0 0;
  position: fixed;
  bottom:0;
  left:0;
  right: 0;
  font-size: 12px;
  background-color: black;
  color: #fffeff;
  padding: 2px 0;
}

.main-section {
  padding-top: 60px;
}


@media screen and (min-width: 480px) {
  .App {
    background-image: url('./volleyball2tiny.jpg');
    background-size: cover;
    
  }

  .main-section {
    padding-top: 100px;
  }

}





